<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">得獎名單</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" />
                列印
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出
                HTML
              </span> -->
            </div>
          </div>
        </div>
        <!-- <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button> -->
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" />
                  HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="
            (row, callback) => {
              row.Published = false;
              row.Ordinal = 0;
              callback();
            }
          "
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-category-id="{ data }">
                <select-box
                  :transfer="true"
                  v-model="data.CategoryId"
                  v-bind="categorySelectOptions"
                ></select-box>
              </template>
              <template #column-channel-id="{ data }">
                <select-box
                  :transfer="true"
                  v-model="data.ChannelId"
                  v-bind="channelSelectOptions"
                ></select-box>
              </template>
            </vxe-form>
          </template>
          <!-- <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="
                $refs.form.validate(errMap => {
                  if (errMap === undefined) $refs.form.dispatchEvent('submit');
                })
              "
            ></vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template> -->
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="
                  grid.changeFilterEvent($event, column, option, $panel, true)
                "
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="
                    grid.changeFilterEvent($event, column, option, $panel)
                  "
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import formatDate from "xe-utils/toDateString";
export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    var printColumns = [
      { field: "Lottery.Category.Name" },
      { field: "Lottery.Product.Name" },
      { field: "Lottery.Time" },
      { field: "InvoiceRecord.Name" },
      { field: "InvoiceRecord.Mobile" },
      { field: "InvoiceRecord.Email" },
      { field: "InvoiceRecord.Address.FullAddress" },
      { field: "InvoiceRecord.Channel.Name" },
      { field: "InvoiceRecord.InvoiceNumber" },
      { field: "InvoiceRecord.OrderNumber" }
    ];

    const gridOptions: GridOptions = {
      stripe: false,
      title: "得獎名單",
      canUpdate: false,
      canDelete: false,
      canCreate: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      sortConfig: {
        multiple: false
      },
      printConfig: {
        sheetName: "得獎名單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "得獎名單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Lottery.Category.Name",
          title: "類別",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Lottery.Product.Name",
          title: "獎項名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Lottery.Time",
          title: "抽獎日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => formatDate(cellValue, "yyyy/MM/dd")
        },
        {
          field: "InvoiceRecord.Name",
          title: "得獎者",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "filter-name" }
        },
        {
          field: "InvoiceRecord.Mobile",
          title: "電話",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "InvoiceRecord.Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          visible: false
        },
        {
          field: "InvoiceRecord.Address.FullAddress",
          title: "地址",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          visible: false
        },
        {
          field: "InvoiceRecord.Channel.Name",
          title: "通路",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "InvoiceRecord.InvoiceNumber",
          title: "發票號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "InvoiceRecord.OrderNumber",
          title: "訂單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        query: model
          ? params => {
              return model.dispatch("winner/query", params);
            }
          : undefined,
        queryAll: model ? () => model.dispatch("winner/query") : undefined,
        save: model
          ? params => model.dispatch("winner/save", params)
          : undefined
      },
      modalConfig: { width: "50%", showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      items: [
        {
          field: "Lottery.Category.Name",
          title: "類別",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Lottery.Product.Name",
          title: "獎項名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },

        {
          field: "Lottery.Time",
          title: "抽獎日期",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true, type: "date" }
          }
        },
        {
          field: "InvoiceRecord.Name",
          title: "得獎者",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "InvoiceRecord.Mobile",
          title: "電話",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "InvoiceRecord.Email",
          title: "Email",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "InvoiceRecord.Address.FullAddress",
          title: "地址",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },

        {
          field: "InvoiceRecord.Channel.Name",
          title: "通路",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "InvoiceRecord.InvoiceNumber",
          title: "發票號碼",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "InvoiceRecord.OrderNumber",
          title: "訂單號碼",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }],
        Mobile: [{ type: "string", required: true }],
        InvoiceNumber: [{ type: "string", required: true }],
        OrderNumber: [{ type: "string", required: true }],
        City: [{ type: "string", required: true }],
        CategoryId: [{ type: "string", required: true }],
        ChannelId: [{ type: "string", required: true }]
      }
    };

    const categorySelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("category/find", value), // eslint-disable-line
        query: params => {
          if (params.condition)
            params.condition.and("ParentId", Operator.IsNull);
          else params.condition = new Condition("ParentId", Operator.IsNull);
          return model!.dispatch("category/query", params); // eslint-disable-line
        }
      },
      treeConfig: { parent: "ParentId", children: "Children" }
    };

    const channelSelectOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇標籤",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: false,
      showHeader: true,
      addIfNotExists: true,
      promises: {
        find: value => model!.dispatch("channel/find", value), // eslint-disable-line
        query: params => model!.dispatch("channel/query", params), // eslint-disable-line
        insert: row => model!.dispatch("channel/insert", row) // eslint-disable-line
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      categorySelectOptions,
      channelSelectOptions
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    }
  }
});
</script>
