
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import formatDate from "xe-utils/toDateString";
export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    var printColumns = [
      { field: "Lottery.Category.Name" },
      { field: "Lottery.Product.Name" },
      { field: "Lottery.Time" },
      { field: "InvoiceRecord.Name" },
      { field: "InvoiceRecord.Mobile" },
      { field: "InvoiceRecord.Email" },
      { field: "InvoiceRecord.Address.FullAddress" },
      { field: "InvoiceRecord.Channel.Name" },
      { field: "InvoiceRecord.InvoiceNumber" },
      { field: "InvoiceRecord.OrderNumber" }
    ];

    const gridOptions: GridOptions = {
      stripe: false,
      title: "得獎名單",
      canUpdate: false,
      canDelete: false,
      canCreate: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      sortConfig: {
        multiple: false
      },
      printConfig: {
        sheetName: "得獎名單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "得獎名單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Lottery.Category.Name",
          title: "類別",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Lottery.Product.Name",
          title: "獎項名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Lottery.Time",
          title: "抽獎日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => formatDate(cellValue, "yyyy/MM/dd")
        },
        {
          field: "InvoiceRecord.Name",
          title: "得獎者",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "filter-name" }
        },
        {
          field: "InvoiceRecord.Mobile",
          title: "電話",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "InvoiceRecord.Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          visible: false
        },
        {
          field: "InvoiceRecord.Address.FullAddress",
          title: "地址",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          visible: false
        },
        {
          field: "InvoiceRecord.Channel.Name",
          title: "通路",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "InvoiceRecord.InvoiceNumber",
          title: "發票號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "InvoiceRecord.OrderNumber",
          title: "訂單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        query: model
          ? params => {
              return model.dispatch("winner/query", params);
            }
          : undefined,
        queryAll: model ? () => model.dispatch("winner/query") : undefined,
        save: model
          ? params => model.dispatch("winner/save", params)
          : undefined
      },
      modalConfig: { width: "50%", showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      items: [
        {
          field: "Lottery.Category.Name",
          title: "類別",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Lottery.Product.Name",
          title: "獎項名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },

        {
          field: "Lottery.Time",
          title: "抽獎日期",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true, type: "date" }
          }
        },
        {
          field: "InvoiceRecord.Name",
          title: "得獎者",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "InvoiceRecord.Mobile",
          title: "電話",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "InvoiceRecord.Email",
          title: "Email",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "InvoiceRecord.Address.FullAddress",
          title: "地址",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },

        {
          field: "InvoiceRecord.Channel.Name",
          title: "通路",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "InvoiceRecord.InvoiceNumber",
          title: "發票號碼",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "InvoiceRecord.OrderNumber",
          title: "訂單號碼",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
            attrs: { type: "text" }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }],
        Mobile: [{ type: "string", required: true }],
        InvoiceNumber: [{ type: "string", required: true }],
        OrderNumber: [{ type: "string", required: true }],
        City: [{ type: "string", required: true }],
        CategoryId: [{ type: "string", required: true }],
        ChannelId: [{ type: "string", required: true }]
      }
    };

    const categorySelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("category/find", value), // eslint-disable-line
        query: params => {
          if (params.condition)
            params.condition.and("ParentId", Operator.IsNull);
          else params.condition = new Condition("ParentId", Operator.IsNull);
          return model!.dispatch("category/query", params); // eslint-disable-line
        }
      },
      treeConfig: { parent: "ParentId", children: "Children" }
    };

    const channelSelectOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇標籤",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: false,
      showHeader: true,
      addIfNotExists: true,
      promises: {
        find: value => model!.dispatch("channel/find", value), // eslint-disable-line
        query: params => model!.dispatch("channel/query", params), // eslint-disable-line
        insert: row => model!.dispatch("channel/insert", row) // eslint-disable-line
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      categorySelectOptions,
      channelSelectOptions
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    }
  }
});
